.checkBox .css-10tj8gt-MuiSwitch-root {
  width: 54px !important;
  height: 34px !important;
}

.checkBox .css-10tj8gt-MuiSwitch-root .MuiSwitch-thumb {
  width: 12px !important;
  height: 12px !important;
}

.checkBox .css-10tj8gt-MuiSwitch-root .MuiSwitch-switchBase {
  left: 2px !important;
  top: -1px !important;
}

.checkBox h6 {
  height: 25px !important;
  display: flex !important;
  align-items: center !important;
  line-height: 13.2px !important;
}

.rightCheck svg {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  right: 0;
  transform: scale(1) translate(50%, -50%);
  color: #00ab55 !important;
}

.badgeSpan span:not(:first-child) {
  margin: 0 !important;
  margin-left: 26px !important;
}

.onlineTable th:nth-child(4),
.onlineTable th:nth-child(4) {
  padding-left: 0px !important;
}

.css-9260rg-MuiTableCell-root:first-child {
  cursor: pointer !important;
}

.css-9260rg-MuiTableCell-root {
  cursor: pointer !important;
  padding: 10px 5px 6px 5px !important;
  vertical-align: top !important;
  border-bottom: 1px solid rgba(241, 243, 244, 1) !important;
}

.deleteModal .css-rm0on-MuiPaper-root-MuiDialog-paper.MuiPaper-rounded {
  max-width: 400px !important;
}

/* .onlineModal .css-1qcgoo3-MuiPaper-root-MuiDialog-paper.MuiPaper-rounded {
    position: relative;
} */

.flexCenterProp {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ratingCell {
  display: flex;
  flex-wrap: nowrap;
  max-width: 120px;
}

.ratingCell .MuiButton-contained {
  border-radius: 50%;
  min-width: auto !important;
  margin: 3px 2px;
  padding: 0px 6px !important;
  height: fit-content;
  font-size: 12px;
}

.ratingCell .MuiButton-outlined {
  border-radius: 50%;
  min-width: auto !important;
  margin: 3px 2px;
  padding: 0px 6px !important;
  height: fit-content;
  font-size: 12px;
}

/* //nikhil css */
/* .MuiInputBase-input,onClick={changeRating}
.MuiSelect-select {
    height: .7rem !important;
    min-height: .7rem !important;
} */
.test {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.star {
  color: #00ab55;
  vertical-align: sub;
  width: 14px !important;
  height: 14px !important;
  margin-bottom: 1px;
}

.CloseBtn svg {
  width: 30px;
  height: 30px;
}

.noteBtn {
  cursor: pointer;
  margin: 0px 5px 0px 17px;
}

.shadiDoneBtn {
  cursor: pointer;
  min-width: 2em;
  margin: 0px 5px 0px 17px;
}

.forms-modals .MuiDialog-paper {
  height: -webkit-fill-available !important;
}

.onlineTable td h6 {
  white-space: unset !important;
}

.MuiAppBar-root {
  height: 53px !important;
}

.topPadding {
  padding-top: 65px !important;
}

.topPaddingremove {
  padding-top: 10px !important;
}

.css-sgoict-MuiCardHeader-action {
  margin-top: 0px !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  top: 0 !important;
}

.css-n26ql7-MuiInputBase-input-MuiOutlinedInput-input,
.css-1eivsbx-MuiInputBase-input-MuiOutlinedInput-input,
.css-9q3kl4-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-9q3kl4-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-9q3kl4-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 11.5px 14px !important;
}

.css-teiej5 {
  height: 50px !important;
  width: 100px !important;
  border-radius: none !important;
}

.css-1q7a0pc {
  background-color: transparent !important;
}

/* .transform-component-module_content__FBWxo {
    display: flex;
    flex-wrap: wrap;
    width: -webkit-fill-available !important;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0;
    padding: 0;
    transform-origin: 0% 0%;
} */

#react-select-2-listbox {
  z-index: 9999;
}

.transform-component-module_wrapper__SPB86 {
  position: relative;
  width: -moz-fit-content;
  width: -webkit-fill-available;
  height: -moz-fit-content;
  height: fit-content;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: auto;
  padding: 0;
}

.staffReportMoreBtn {
  background-color: #fdfdfd;
  color: #8a8486;
  border: 1px solid #8a8486;
  border-radius: 8px;
}

.staffReportMoreBtn:active {
  background-color: #d1c1c7;
  color: #fdfdfd;
}

.online-info {
  text-align: center;
  display: inline;
  border-radius: 5px;
  padding: 3px;
  font-size: smaller;
}

.online-warning-outline {
  text-align: center;
  display: inline;
  /* border: 1px solid #ed1c24; */
  background-color: #40C91A;
  color: white;
  border-radius: 5px;
  padding: 3px;
  font-size: smaller;
}

.online-primary {
  color: #ed1c24;
}

.online-success-outline {
  text-align: center;
  display: inline;
  border: 1px solid #fff;
  background-color: green;
  color: #fff;
  border-radius: 5px;
  padding: 3px;
  font-size: smaller;
}
.online-outline {
  text-align: center;
  display: inline;
  border: 1px solid #000000;
  background-color: #fff;
  color: #000000;
  border-radius: 5px;
  padding: 3px;
  font-size: smaller;
}

.corner-ribbon-new {
  position: absolute;
  top: 0;
  left: 0;
  height: 2.8em;
  width: 2.8em;
  padding: 2px;
}

.corner-ribbon-new .cr-inner-new {
  position: absolute;
  inset: 0;
  background: green;
  color: white;
  border-radius: 12px 5px 0 5px;
  clip-path: polygon(0 0, 100% 0, 0 100%);
}

.corner-ribbon-new .cr-text-new {
  display: block;
  font-size: 0.4em;
  line-height: 0.6;
  transform: rotate(-45deg) translateY(-0.2em) translateX(-1.6em);
  font-weight: 500 !important;
  text-align: center;
}
