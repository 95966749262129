.primaryBg {
  background: #32be8f;
}

.userAppBg {
  background: #f31f3a !important;
}
.secondaryBG {
  background: #525ffe !important;
}

.light {
  color: #fff;
}
.primaryColor {
  color: #a9a7a7;
}
.secondaryColor {
  color: red;
}
.displayBtwPro {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
button.MuiButtonBase-root.MuiButton-root.btnPadding:hover {
  box-shadow: 0 0px 0px !important;
  background-color: transparent !important;
}
.btnPadding {
  padding: 10px 18px !important;
  color: #525ffe !important;
  border: 1px solid #525ffe !important;
}
button.MuiButtonBase-root.MuiButton-root.btnPadding.css-n7eggy {
  background: #525ffe !important;
  color: #fff !important;
}

.btnPadding.css-r7nieh-MuiButtonBase-root-MuiButton-root {
  background: #525ffe !important;
  color: #fff !important;
}
button.MuiButtonBase-root.MuiButton-root.ratingSec.css-n7eggy {
  background: #32be8f !important;
  color: #fff !important;
}
.btnPadding:active {
  color: #fff !important;
  background: #525ffe !important;
}
.MuiButtonBase-root.ratingSec {
  margin: 0px 3px;
  border: 2px solid #32be8f;
  color: #32be8f;
  font-size: 11px;
}
.ratingSec.css-r7nieh-MuiButtonBase-root-MuiButton-root {
  background: #32be8f;
  color: #fff;
}

.MuiButtonBase-root.ratingSec:active {
  background: #32be8f;
  color: #fff;
}
.MuiButtonBase-root.ratingSec:hover {
  border: 2px solid #35ba8e;
  background-color: #35ba8e29;
  color: #32be8f;
  box-shadow: 0px 0px 0px !important;
}

button.MuiButtonBase-root.roundBtn {
  border-radius: 30px;
  border: 1.5px solid;
}

.offlineCard {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.offlineCard-Isonline {
  box-shadow: gold 0px 7px 11px 0px;
  border: 2px solid gold;
  border-radius: 18px;
  position: relative;
  overflow: hidden;
}

.goldenBadge {
  position: absolute;
  background-color: gold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  font-size: 0.6em;
  padding: 3px;
  transform: rotate(-44deg);
  left: -28px;
  width: 89px;
  top: -4px;
}
.textDark{
  color: #333 ;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-18 {
  font-size: 18px;
}
.fw-500 {
  font-weight: 500;
}
.WtsBtn {
  padding: 0px !important;
  height: 46px;
  width: 46px;
  border-radius: 50%;
  margin: 3px;
  background: none;
  border: 1.6px solid;
}
.ShareSec {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 5px 8px;
  color: #afafaf;
}
.selectBtn {
  background: transparent !important;
  border: 2px solid #fff !important;
  border-radius: 5px !important;
  color: #fff !important;
  margin-right: 3px !important;
}
.selectBtn:active {
  color: #32be8f !important;
  border: 2px solid #fff !important;
  background: #fff !important;
}
.css-g72avy-MuiTableCell-root {
  padding: 20px 8px !important;
}
.DesignationBtn {
  font-weight: 500;
  background: #3366ff;
  color: #fff;
  padding: 3px 5px;
  border-radius: 9px;
}
.Bg-red {
  background: #ff3030 !important;
}
td.MuiTableCell-root.boderNone {
  border: 0px !important;
  padding: 2px 0px !important;
}

.CardNameShow {
  position: absolute;
  top: 18px;
  left: 15%;
  background: #eb445a;
  color: #fff;
  padding: 2px 10px;
  border-radius: 12px;
  z-index: 999;
}
.OnlineHighlight {
  background: #c7c2c2;
  color: #fff;
  padding: 3px 10px;
  font-size: 12px;
  border-radius: 5px;
}
button.MuiButtonBase-root.group-link-donebtn {
  background: #32be8f;
}
.doneBtnSelected {
  margin: 10px 0px !important;
  background-color: #32be8f !important; /* Set background color when selected */
  color: white !important; /* Set text color when selected */
}

.doneBtnUnselected {
  margin: 10px 0px !important;
  background-color: transparent !important; /* Set transparent background when unselected */
  border: 1px solid #32be8f !important; /* Set green border when unselected */
  color: #32be8f !important; /* Set text color when unselected */
}
